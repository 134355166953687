
// VIP mixins

sectionOuter(background-color, padding = 30px 0px 30px)
  width 100%
  padding padding
  background-color background-color
  +below($wide)
    padding 20px 30px 20px
  +below($desktop)
    padding 20px 20px 20px
  +below($tablet)
    padding 20px 20px
  +below($mobile)
    padding 20px 10px

sectionInner(border-color = transparent)
  width: $page_width
  margin: 0 auto;
  border-top: 2px solid border-color
  +below($wide)
    width: 100%;
  +below($mobile)
    padding 20px 0px 25px 0
  h2
    font-size: 1.8rem
    +below($mobile)
      font-size 1.3rem
      margin: 0px 0px 10px 0px




// Overall variables
$vip_outer_margin_top = 0px
$vip_section_background_color = transparent
$vip_inner_section_border_color = #D2DCDD
$vip_headers_border = 0;
$vip_headers_padding = 0;
$vip_last_section_padding = 60px;
// Favorites
$favorites_heart_fill = transparent
$favorites_heart_stroke = #A0A0A0

// Gallery
$gallery_thumb_radius = 5px
$gallery_outer_padding = 40px 0 0 0

// Stock status
$stock_padding = 18px 20px 0px
$stock_background_color = #f1f1f1
$stock_border_radius = 5px

// Services
$servies_margin = 20px 0px 10px 0
$servies_padding = 15px 20px 18px
$services_border_style = 1px solid #ebebeb;
$services_background_color = #f1f1f1
$services_border_radius = 0
$services_show_hide_background_color = #f1f1f1
$services_ellipsis_gradient_start = rgba(241, 241, 241, 0)
$services_ellipsis_gradient_end = rgba(241, 241, 241, 1)

// Specsifications
$specifications_row_color = #D2DCDD
$help_icon_size = 16px
$help_icon_background_color = #999

// Reviews
$reviews_card_shadow = none
$reviews_card_border = 1px solid #ccc;
$reviews_card_border-radius = 5px
$reviews_show_hide_background_color = #fff
$reviews_ellipsis_gradient_start = rgba(255, 255, 255, 0)
$reviews_ellipsis_gradient_end = rgba(255, 255, 255, 1)

// Product Pairs
$product_pair_header_usp_font_size = 1.3rem
$product_pair_header_usp_font_weight = bold
$product_pair_header_usp_font_family = $font_family_base
$product_pair_header_bg_color = $brand_color
$product_pair_header_usp_bg_color = $brand_color
$product_pair_header_usp_border_right = 1px dashed #fff
$product_pair_header_usp_padding = 0px 20px 0px 15px
$product_pair_header_text_color = #fff
$product_pair_header_text_padding = 0 0 0 20px
$product_pair_header_text_border = none
$product_pair_partner_bottom_bg_color = #6d9196
$product_pair_partner_list_border_top = 2px solid #6d9196

// Additional Info
$description_section_background_color = #dbdbdb

// Price box
$vip_price_box_border_radius = 0
$vip_price_box_border = 1px solid #ebebeb
$vip_price_box_box_shadow = none
$vip_price_box_save_button_gutter = 1px
$vip_price_box_save_button_background_color = $action_color
$vip_price_box_save_button_border_color = $action_color
$vip_price_box_save_button_icon_stroke_color = transparent
$vip_price_box_save_button_icon_background_color = #fff
$vip_price_box_text_transform = uppercase
$vip_price_box_top_padding = 16px 20px 0 16px
$vip_price_box_top_padding_mobile = 14px 0 0 14px
$vip_price_box_bottom_padding = 0 16px 16px 16px
$vip_price_box_bottom_padding_mobile = 0 14px 14px 14px
$vip_price_box_font_family = $font_family_base
