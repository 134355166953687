.skou-bf__countdown-wrapper
  background-color: #dcdadc
  background-size: cover;
  min-height: 1000px
  .newsletter-signup
    &__wrapper
      padding 20px
      .checkbox__label
        color #ffffff
    &__conditions
      color #ffffff


.skou-bf__countdown-content
  pageWrapper()

.skou-bf__header-box
  display: flex
  background-image: url(//images.wagcdn.com/f/landingpages/blackfriday2018/countdown/skou/top-banner-bg.jpg)
  background-size: cover;
  height: 375px
  flex-direction: row-reverse;
  border-radius: 50px
  +below($desktop)
    background-image: none;
    background: linear-gradient(to bottom, #ffffff 20%,#000000 20%,#000000 80%,#ffffff 80%);
    flex-direction: row;
    justify-content: center;
  +below($tablet)
    height: 300px

.skou-bf__countdown-box-countdown
  display: flex;
  flex-direction: column;
  align-items: center
  justify-content: center;
  padding-right: 20px
  .countdown-label
    color: #fff
  +below($tablet)
    padding: 0

.skou-bf__countdown-header
  font-size: 2.4rem
  color: #fff
  font-family: $font_family_header
  text-align: center;
  +below($tablet)
    font-size: 1.9rem

.skou-bf__countdown-box
  display: flex
  border-radius: 50px
  margin-top: 20px
  background-color: #000
  overflow: hidden;
  +below($tablet)
    flex-direction: column;

.skou-bf__countdown-box-newsletter
  display: flex;
  flex-direction: column;
  width: 50%
  +below($tablet)
    width: 100%

.skou-bf__countdown-box-facebook
  width: 50%
  a
    display: flex;
    flex-direction: column;
  img
    vertical-align: top;
  +below($tablet)
    width: 100%
    
.skou-bf__countdown-appetizers
  display: flex
  margin-top: 20px
  justify-content: space-between;
  +below($tablet)
    flex-direction: column;
    a
      display: flex
      justify-content: center;
      margin-top: 20px
