.skou-header
  position: relative;
  background-color: $header_background_color

.skou-header__inner
  max-width: $page_width
  background-color: $header_background_color
  margin: 0 auto
  display: flex;
  position: relative;
  flex-direction: column;
  height: 190px
  padding-top: 12px;
  +below($wide)
    padding-left: 20px;
    padding-right: 20px;
  +below($desktop)
    height: $header_height_mobile;
    padding: 0 $header_height_mobile 0 10px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    z-index: $mobile_menu_Z;
    width: 100%;
    box-shadow: $shadow-md;

    .search {
      width: 100%;

      .search-active & {
        border-color: #E0E0E0;
      }
    }

    .menu-trigger__bar {
      background-color: $brand_color;
    }

    .menu-trigger_label {
      color: $brand_color;
    }

    .search__submit__icon > svg {
      fill: $brand_color;
    }

.skou-header__placeholder
  height: $header_height_mobile;
  +above($desktop)
    display:none;

.skou-header__usp
  position: absolute;
  right: 0
  top: 13px
  a
    display: flex;
    align-items: center;
    color: $body_text_color
  svg
    box(13px)
    fill: #000
    margin-left: 7px
  +below($wide)
    right: 20px
  +below($desktop)
    display: none

.skou-header__bottom
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%
  margin: 8px 0 26px 0
  +below($desktop)
    margin: 0
    padding: 0
    width: auto;
  .search-active &
    width: 0
    opacity: 0

.skou-header__logo
  display: flex;
  justify-content: center;
  align-items:flex-start;
  img
    height: 110px
  +below($desktop)
    display: none

.skou-header__icons__first
.skou-header__icons__last
  display: flex
  gap: 42px;
  width: 35%
  margin: 0
  +below($desktop)
    gap: 0;

.skou-header__icons__first
  +below($desktop)
    display: none

.skou-header__icons__last
  justify-content: flex-end;
  +below($desktop)
    width: 100%
    margin: 0

//Specific icons width
.skou-header__icon__truck svg
  width: 58px
.skou-header__icon__paper svg
  width: 51px
.skou-header__icon__stores svg
  width: 49px
.skou-header__icon__help svg
  width: 44px
.skou-header__icon__csa svg
  width: 45px
.skou-header__icon__hearts svg
  width: 43px
.skou-header__icon__basket svg
  width: 53px

.skou-header__icon
  display: flex
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position relative
  cursor: pointer;
  text-decoration: none;
  color: $body_text_color
  &:hover
    text-decoration: none
  +below($desktop)
    width: 42px
    color: #fff
    margin-left: 12px
    display: none
    span
      display: flex;
      align-items: center;
    &.active,
    &.full
      display: flex
    +below($desktop)
      border:0
  .chat-open
    color: $success_color
  .chat-closed
    color: $danger_color
  svg
    fill: #b9c5c6
    height: 50px
    +below($desktop)
      fill: $brand_color
      box(30px)

.skou-header__icons__amount
  display: flex;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  align-items: center;
  justify-content: center;
  circle(21px)
  background-color: #700A0C
  color: #fff
  font-size: .8rem
  top: 14px
  right 0
  .active &,
  .full &
    visibility: visible;
    animation-name: zoomIn;
    animation-duration: .4s;
  +below($desktop)
    circle(18px)
    font-size: .7rem
    top: 5px
    right 0

.skou-header__icons__amount-heart
  display: flex;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  align-items: center;
  justify-content: center;
  circle(21px)
  background-color: #700A0C
  color: #fff
  font-size: .8rem
  top: 14px
  right 24px
  .active &,
  .full &
    visibility: visible;
    animation-name: zoomIn;
    animation-duration: .4s;
  +below($desktop)
    circle(18px)
    font-size: .7rem
    top: 5px
    right 0

.skou-header__icons__text
  margin-top: 5px
  font-size: .9rem
  +below($desktop)
    display: none

.skou-header__mobile-wrap
  display: none
  +below($desktop)
    display: flex
    align-items: center;
  .search__submit__icon
    right: 14px

.skou-header__mobile-logo
  display: none
  height: $header_logo_height_touch;
  width: $header_logo_width_touch;
  margin: 0;
  svg
    fill: #fff
    max-width: 100%
    height: 100%
  +below($desktop)
    display: block
    position relative
    .search-active &
      width: 0
      opacity: 0
