.footer-main
  position: relative;
  background-color: $brand_color
  color: #fff;

  a
    color: #fff;

.footer-main__inner
  max-width: $page_width
  margin: 0 auto
  padding: 30px 0px 0px
  +below($wide)
    padding: 30px 30px 0px
  +below($desktop)
    padding: 20px 20px 0px
  +below($mobile)
    padding 20px 15px 0px

.footer-main__links
  display: flex;
  +below($tablet)
    flex-wrap: wrap;

.footer-main__column
  width: 20%;
  padding-right 30px
  padding-bottom 20px
  +below($desktop)
    width 23%
  +below($tablet)
    width: 50%;
    &:nth-child(2n)
      padding-right 0
  &.mobile-only
    display none
    +below($tablet)
      display block
  h4
    font-size: 0.875rem
    font-weight bold
    margin 30px 0 13px
    padding-bottom 6px
    text-transform uppercase
    border-bottom: 1px solid #e82b38
    color: #fff
    +below($desktop)
      margin-top 10px
    +below($tablet)
      margin-top 0
  ul
    list-style: none;
    margin 0
    padding 0
  ul li
    margin-bottom: 5px
    +between($tablet + 1, $desktop)
      margin-bottom 0
  a
    font-size 0.875rem
    +between($tablet + 1, $desktop)
      font-size 0.7rem

.footer-main__usp
  width: 40%;
  display flex
  justify-content flex-end
  align-items flex-end
  background-color $brand_color
  img
    display block
  +below($desktop)
    width 31%
  +below($tablet)
    display none

.footer-main__social
  margin-top: 80px
  p
    margin-bottom: 4px
  svg
    float:right
    box(35px)
    fill: #fff
    margin-left: 10px

  &.desktop
    +below($wide)
      display:none

.footer-sub
  background-color: #700A0C;

.footer-sub__inner
  max-width: $page_width
  margin: 0 auto
  display flex
  justify-content space-between
  align-items center
  height 52px
  +below($wide)
    padding: 0px 30px
  +below($desktop)
    padding: 0px 20px
  +below($tablet)
    height inherit
    padding 20px
    align-items flex-start
  +below($mobile)
    padding 15px

.footer-sub__info
  display flex
  +below($tablet)
    flex-direction column
  span
    color #fff
    font-size 0.875rem
    padding 0px 15px
    border-left 1px solid #e82b38
    &:first-child
      padding-left 0
      border-left 0px
    +between($tablet + 1, $desktop)
      padding 0px 10px
      font-size 0.55rem
    +below($tablet)
      padding 3px 0px
      border 0
  a
    color #fff
    text-decoration underline
  .skousen-co2
    margin -12px
    padding-left 15px
    vertical-align baseline
    +below($mobile)
      max-width 100px
      margin -12px

  .skousen-co2-wrapper
    +below($mobile)
      margin 3px 0px 3px
.footer-sub__social
  display flex
  align-items center
  color #fff
  font-size 0.625rem
  +below($tablet)
    flex-direction column
    align-items flex-end
  img
    padding-right 5px
    height 20px
    margin-top 2px
    +below($tablet)
      margin-top 0px
      padding-right 0
      height 18px
      margin-bottom 7px
  div
    display flex
  a
    font-size 0px
    margin-left 6px
  svg
    box(35px)
    fill #fff
    +below($tablet)
      box(30px)

.footer__chat
  cursor: pointer;
  &:hover
    text-decoration: underline

.footer-skousen__chat-text
  font-size: 0.875rem;
  margin-bottom: 5px

.footer__chat-online
  color: $success_color

.footer__chat-offline
  color: $danger_color
  